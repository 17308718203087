<template>
  <div class="config-provider" :style="controller.state.value?.style">
    <link
      v-if="controller.state.value?.fontFamilyUrl"
      :href="controller.state.value.fontFamilyUrl"
      rel="stylesheet"
    />
    <AntConfigProvider
      :theme="controller.state.value?.antTheme"
      :locale="controller.state.value?.antLocale"
    >
      <slot />
    </AntConfigProvider>
  </div>
</template>

<script lang="ts" setup>
import { ConfigProvider as AntConfigProvider } from 'ant-design-vue';
import { onMounted, provide, watch } from 'vue';
import { ConfigProviderController, type ConfigProviderProps } from './ConfigProviderController';

const props = defineProps<ConfigProviderProps>();
const controller = new ConfigProviderController();

provide('playerConfig', controller.state);

watch(
  () => [props.background, props.fontFamily, props.locale, props.mainColor],
  ([background, fontFamily, locale, mainColor]) => {
    controller.update({
      background,
      fontFamily,
      locale,
      mainColor,
    });
  },
);

onMounted(() => {
  controller.update({
    background: props.background,
    fontFamily: props.fontFamily,
    locale: props.locale,
    mainColor: props.mainColor,
  });
});
</script>

<style lang="scss">
@import '../../style/widgets.scss';
</style>

<style scoped lang="scss">
@import '../../style/widgets.scss';

.config-provider {
  height: 100%;
}

:deep() {
  a {
    color: black;
  }

  main {
    display: flex;
    flex: 1;
    width: 100%;
    overflow: auto;
  }

  hr {
    height: 1px;
    width: 100%;
    background: #8692a5;
    border: none;

    &.small {
      width: 100px;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
    max-width: calc(100% - 2rem);
    margin: auto;
    gap: 60px;
    font-family: var(--font-family, sans-serif);
    color: var(--font-color, #000);

    &.full-width {
      width: 90%;
    }

    .form-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 50px;
    }

    .widget {
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .input {
        @include input;
        width: 100%;
        box-sizing: border-box;
        transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
        outline: none;

        &.error {
          border-bottom-color: $error-color;
          border-bottom-width: 3px;
        }

        &.disabled,
        &:disabled {
          background-color: #f0f0f0;
          color: #777777;
        }
      }

      input::placeholder {
        font-family: var(--font-family, sans-serif);
        font-style: normal;
        font-weight: 400;
        color: #272d35;
        opacity: 0.3;
      }

      textarea::placeholder {
        font-family: var(--font-family, sans-serif);
        font-style: normal;
        font-weight: 400;
        color: #272d35;
        opacity: 0.3;
      }

      .input,
      .p-autocomplete-input,
      .p-autocomplete-item,
      input::placeholder,
      textarea::placeholder {
        @include placeholder;
      }

      .v-select {
        .vs__dropdown-toggle {
          @include input-hover-active-border;
          .vs__selected-options {
            overflow: hidden;
          }
        }
      }
    }

    .text {
      white-space: pre-wrap;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 0.5em;
    }

    .text,
    .link {
      font-size: 1.5rem;
      text-align: left;
    }
    .subtitle {
      @include text-small;
    }
    .link,
    .link:visited {
      color: var(--color-main-hover);
    }

    .button,
    .next-button {
      @include main-button;

      .icon {
        margin-right: 8px;
      }

      &__disabled {
        background: var(--color-main-hover);
        opacity: 0.5;
        cursor: default;
      }
    }

    .next-button {
      align-self: flex-end;
      @include text-normal;
    }

    .secondary-button {
      @include secondary-button;
    }

    .tags {
      * {
        color: $main-color-dark;
      }
    }

    .card {
      background-color: white;
      @include shadow;
      border-radius: 12px;
      color: $main-color-dark;
    }
  }

  .button-icon {
    width: 24px;
    height: 24px;
    fill: var(--color-main);
    stroke: var(--color-main);
    margin-right: 10px;
  }

  .secondary-button {
    .button-icon {
      fill: var(--abstra-main-color-dark);
      stroke: var(--abstra-main-color-dark);
    }
  }
}
</style>
