import { theme } from 'ant-design-vue';
import { ThemeConfig } from 'ant-design-vue/es/config-provider/context';
import { Locale } from 'ant-design-vue/es/locale';
import deDe from 'ant-design-vue/es/locale/de_DE';
import enUS from 'ant-design-vue/es/locale/en_US';
import esES from 'ant-design-vue/es/locale/es_ES';
import frFR from 'ant-design-vue/es/locale/fr_FR';
import hiIN from 'ant-design-vue/es/locale/hi_IN';
import ptBR from 'ant-design-vue/es/locale/pt_BR';
import { ref, Ref, StyleValue } from 'vue';
import { alternate, isColor, isColorDark, isImageDark, lighten } from './colorHelpers';

export const DEFAULT_FORM_FONT_COLOR = '#000';
export const LIGHT_FONT_COLOR = '#ffffff';
export const DARK_FONT_COLOR = '#1b1b23';

export type ConfigProviderProps = {
  background: string;
  mainColor: string;
  fontFamily: string;
  locale: string;
};

export type PlayerConfigState = {
  antLocale: Locale | undefined;
  style: StyleValue | undefined;
  fontFamilyUrl: string | undefined;
  antTheme: ThemeConfig | undefined;
};

export class ConfigProviderController {
  state: Ref<PlayerConfigState> = ref({
    antLocale: undefined,
    style: undefined,
    fontFamilyUrl: undefined,
    antTheme: undefined,
  });

  imageIsDarkCache: Record<string, boolean> = {};

  update = async (newProps: ConfigProviderProps) => {
    this.state.value.antLocale = this.getAntLocale(newProps);
    this.state.value.style = await this.getStyle(newProps);
    this.state.value.fontFamilyUrl = this.getFontUrl(newProps.fontFamily);
    this.state.value.antTheme = await this.getAntTheme(newProps);
    this.updateGlobalFontFamily(newProps.fontFamily);
  };

  private updateGlobalFontFamily = (fontFamily: string) => {
    document.documentElement.style.setProperty('--ac-global-font-family', fontFamily);
  };

  private getAntLocale = (props: ConfigProviderProps) => {
    return (
      {
        en: enUS,
        pt: ptBR,
        es: esES,
        de: deDe,
        fr: frFR,
        hi: hiIN,
      }[props.locale] || enUS
    );
  };

  private getBackgroundStyle(background: string) {
    if (isColor(background)) {
      return { backgroundColor: background };
    }
    return {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
    };
  }

  private isBackgroundDark = async (background: string): Promise<boolean> => {
    if (this.imageIsDarkCache[background]) {
      return this.imageIsDarkCache[background];
    }

    if (isColor(background)) {
      this.imageIsDarkCache[background] = isColorDark(background);
      return this.imageIsDarkCache[background];
    }

    this.imageIsDarkCache[background] = await isImageDark(background);

    return this.imageIsDarkCache[background];
  };

  private getStyle = async (props: ConfigProviderProps): Promise<StyleValue> => {
    const buttonFontColor = (color: string) =>
      isColorDark(color) ? LIGHT_FONT_COLOR : DARK_FONT_COLOR;

    const isBackgroundDark = await this.isBackgroundDark(props.background);

    return {
      '--color-main': props.mainColor,
      '--color-main-light': lighten(props.mainColor, 0.15),
      '--color-main-hover': alternate(props.mainColor),
      '--color-main-active': alternate(props.mainColor),
      '--color-secondary': 'transparent',
      '--color-secondary-lighter': 'transparent',
      '--color-secondary-darker': 'transparent',
      '--button-font-color-main': buttonFontColor(props.mainColor),
      '--font-family': props.fontFamily,
      '--font-color': isBackgroundDark ? LIGHT_FONT_COLOR : DARK_FONT_COLOR,
      ...this.getBackgroundStyle(props.background),
    };
  };

  private getAntTheme = async (props: ConfigProviderProps): Promise<ThemeConfig> => {
    const isBackgroundDark = await this.isBackgroundDark(props.background);

    const token = {
      fontFamily: props.fontFamily,
      colorPrimary: props.mainColor,
    };

    const algorithm = [];

    if (isBackgroundDark) {
      const { darkAlgorithm } = theme;
      algorithm.push(darkAlgorithm);
    }

    return {
      token,
      algorithm,
    };
  };

  private getFontUrl = (fontFamily: string) =>
    `https://fonts.googleapis.com/css2?family=${fontFamily
      .split(' ')
      .join('+')}:wght@300;400;500;700;900&display=swap`;
}
